const debounceTime = 1000;

var ProductFilter = {
    resetButton: $('.js-reset-filter'),
    init: function () {
        $(document).on('change', '.filterbar .level_2, .filterbar .level_3', _.debounce(ProductFilter.reloadProductsFilter, debounceTime));
        $(document).on('click', '.filterbar .color-filter .color-filter__input', ProductFilter._clickOnColorFilter);
        $(document).on('click', '.filterbar .js-reset-filter', ProductFilter.reset);

        $(document).on(
            'change',
            '.filterbar .filterbar__group.ajax_filter',
            _.debounce(function () {
                ProductFilter.reloadResults(false);
            }, debounceTime)
        );
    },

    reset: function () {
        ProductFilter.resetButton.addClass('disabled');
        window.location = $('#products_list').val() + '#level_2_id=' + $('select[name=level_2_id]').val();
        window.location.reload();
    },

    // get Hash out of URL
    getHash: function (key) {
        var params_tmp = location.hash.substr(1).split('&'),
            params = {};

        params_tmp.forEach(function (val) {
            var splitter = val.split('=');
            params[splitter[0]] = splitter[1];
        });

        if (key) {
            return params[key];
        }

        return params;
    },

    _clickOnColorFilter: function (e) {
        if ($(this).find('img').hasClass('selected')) {
            $(this).find('img').removeClass('selected');
        } else {
            $(this).find('img').addClass('selected');
        }

        ProductFilter.reloadResults(false);
    },

    addLoadingOverlay: function (e) {
        $('.resultlist').html(`
            <div class='pace'><span class='progress' style='width: 10px'></span><span class='label'>Lade Produkte ...</span></div>
            <div class='fallback fallback--headline'></div>
            <div class='fallback fallback--span'></div>
            <div class='fallback fallback--result'></div>
            <div class='fallback fallback--result'></div>
            <div class='fallback fallback--result'></div>
        `);
    },

    reloadProductsFilter: function (e, initial) {
        console.log('== reloadProductsFilter == ');

        const hashes = ProductFilter.getHash();

        /** CATEGORY LEVEL MANAGEMENT */
        const level1Elem = $('.filterbar .level_1');
        const level2Elem = $('.filterbar .level_2');

        if (initial) {
            if (hashes && hashes.level_2_id) {
                level2Elem.val(hashes.level_2_id);
            }
        }

        const level1Id = level1Elem.val();
        const level2Id = level2Elem.val();

        $('.filterbar .level_3').parent().removeClass('d-block');

        $('.filterbar .level_3').parent().addClass('d-none');

        const currentLevel2Elem = $('.filterbar .category_level_' + level2Id);

        if (currentLevel2Elem.length) {
            currentLevel2Elem.parent().removeClass('d-none');
            currentLevel2Elem.parent().addClass('d-block');

            if (initial) {
                if (hashes && hashes.level_3_id) {
                    currentLevel2Elem.val(hashes.level_3_id);
                }
            }
        }

        ProductFilter.reloadFilter(initial, () => {
            if ('wstoffname' in hashes && !(hashes.wstoffname === '')) {
                const hashwst = decodeURI(hashes.wstoffname);
                $('[name=wstoffname]').val(hashwst).trigger('change');
            }
        });
        ProductFilter.reloadResults('search');

        console.log('== /reloadProductsFilter == ');
    },

    reloadSelect2: function () {
        /**
         * Darf Select2 nur laden, wenn auf der Products-Page Seite
         * sonst funktionieren andere Select2-Instanzen nicht
         */
        if ($('body').hasClass('page-products')) {
            $('.select2').select2({
                closeOnSelect: false,
            });
        }
    },

    reloadUI: function () {
        $('.js-shop-products').on('click', function (e) {
            e.preventDefault();

            $(this)
                .closest('.result-item')
                .find('.products.datatable')
                .each(function () {
                    if ($(this).attr('rendered') != '0' || $(this).hasClass('no-footer')) {
                        return;
                    }

                    let colCount = $(this).find('th').length;
                    let defaultSort = $(this).data('defaultsort');
                    let ordering = [[1, 'asc']];

                    if (defaultSort != '') {
                        $(this)
                            .find('th')
                            .each(function (i) {
                                let key = $(this).data('key');

                                if (key == defaultSort) {
                                    ordering = [[i, 'asc']];
                                }
                            });
                    }

                    let columnsDefs = [
                        {
                            targets: 0,
                            orderable: false,
                        },
                    ];

                    colCount--; // Erste Spalte abziehen

                    for (var i = 1; i <= colCount; i++) {
                        let th = $(this).find('th').get(i);

                        columnsDefs.push({
                            targets: i,
                            orderable: !$(th).hasClass('nosort'),
                            type: 'numeric-comma',
                        });
                    }

                    $(this).DataTable({
                        order: ordering,
                        info: false,
                        paging: false,
                        searching: false,
                        scrollX: true,
                        columnDefs: columnsDefs,
                        autoWidth: false,
                    });

                    $(this).attr('rendered', '1');
                });

            let element = $(this);
            let table = element.closest('.result-item').find('.result-item__products');

            table.toggleClass('show');
        });
        window._reloadInventoryState();
        ProductFilter.resetButton.removeClass('disabled');
    },

    reloadFilter: function (initial, callback) {
        let hashes;
        var ajaxUrl = $('#products_ajax_get_filter').val();

        if (!initial) {
            hashes = ProductFilter.getCurrentHashState();
            let delimiter = '&';
            let tokens = hashes.split(delimiter).slice(0, 2);
            hashes = tokens.join(delimiter);
        } else {
            hashes = location.hash.substr(1);
        }

        $.ajax(ajaxUrl, {
            method: 'POST',
            data: {
                _token: $('#_token').val(),
                params: hashes,
            },
            success: function (resp) {
                $('.ajax_filter').html(resp);

                // Change Hash URL
                window.location.hash = '#' + ProductFilter.getCurrentHashState();
                ProductFilter.reloadSelect2();

                if (callback) callback();
            },
            error: function (resp) {
                console.log('reloadFilter: Could not load Filter!');
                console.log(resp);
                alert($('#i18n_generic_ajax_error').val());

                // @TODO: SHOW HIDE MASK
            },
        });
    },

    _startPace: function () {
        ProductFilter.resetButton.addClass('disabled');
        $('.pace .progress').css('width', '10%');
        $('.pace .label').css('color', '#575757');

        $('.pace').show();
    },

    _stopPace: function () {
        ProductFilter.resetButton.removeClass('disabled');
        $('.pace .progress').css('width', '100%');

        setTimeout(() => {
            $('.pace').hide();
        }, 3000);
    },

    _progressPace: function (num) {
        $('.pace .progress').css('width', num + '%');

        if (num > 50) {
            $('.pace .label').css('color', 'white');
        } else {
            $('.pace .label').css('color', '#575757');
        }
    },

    reloadResults: function (initial) {
        let hashes;
        var ajaxUrl = $('#products_ajax_get_results').val();

        if (!initial) {
            hashes = ProductFilter.getCurrentHashState();
            ProductFilter.addLoadingOverlay();
        } else if (initial === true) {
            hashes = location.hash.substr(1);
        } else if (initial === 'search') {
            hashes = location.hash.substr(1);
            let delimiter = '&';
            let tokens = hashes.split(delimiter).slice(0, 2);
            hashes = tokens.join(delimiter);
        }

        ProductFilter._startPace();

        $.ajax(ajaxUrl, {
            method: 'POST',
            data: {
                _token: $('#_token').val(),
                params: hashes,
            },
            success: function (resp) {
                $('.resultlist').html(resp);

                //ProductFilter._stopPace();

                ProductFilter.reloadUI();
            },
            error: function (resp) {
                console.log('reloadResults: Could not load Filter!');
                console.log(resp);

                alert($('#i18n_generic_ajax_error').val());
                ProductFilter.resetButton.removeClass('disabled');

                // @TODO: SHOW HIDE MASK
            },
            xhr: function () {
                var xhr = new window.XMLHttpRequest();
                xhr.count = 0;

                //Upload progress
                /*xhr.upload.addEventListener(
                    'progress',
                    function(evt) {
                        if (evt.lengthComputable) {
                            var percentComplete = evt.loaded / evt.total / 2;
                            //Do something with upload progress
                            ProductFilter._progressPace(percentComplete);
                        }
                    },
                    false
                );*/
                //Download progress
                xhr.addEventListener(
                    'progress',
                    function (evt) {
                        if (evt.lengthComputable) {
                            var percentComplete = evt.loaded / evt.total;
                            //Do something with download progress
                            ProductFilter._progressPace(percentComplete);
                        } else if (this.explicitTotal) {
                            ProductFilter._progressPace(Math.min(1, event.loaded / self.explicitTotal));
                        } else {
                            if (evt.currentTarget.readyState == 3) {
                                ProductFilter._progressPace(75);
                            } else if (evt.currentTarget.readyState == 4) {
                                ProductFilter._progressPace(100);
                            } else {
                                ProductFilter._progressPace(50);
                            }
                        }
                    },
                    false
                );
                return xhr;
            },
        });
    },

    getCurrentHashState: function () {
        let url = '';

        /**
         * Normale Selects
         */
        $('.filterbar .filter-item select').each(function () {
            if ($(this).parent().hasClass('d-none')) return;

            let name = $(this).attr('name');
            let search = '';

            let selected = $(this).find(':selected');

            if (!selected.length) return (url += name + '=' + search + '&');

            selected.each(function () {
                search += `${$(this).val()}|`;
            });

            url += name + '=' + search.replace(/\|$/, '') + '&';
        });

        /**
         * Wenn ColorFilter existieren
         */
        if ($('.filterbar .color-filter').length) {
            url += $('.filterbar .color-filter').attr('name') + '=';

            $('.filterbar .color-filter .selected').each(function () {
                url += $(this).attr('value') + '|';
            });

            url.trimEnd('|');
        }

        window.location.hash = url.trimEnd('&');
        return url.trimEnd('&');
    },
};

if ($('.filterbar').length) {
    ProductFilter.init();
    ProductFilter.reloadProductsFilter(undefined, true);
}
