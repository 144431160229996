import Cookies from 'js-cookie';
import print from './_print';

$(function() {
    $('.default.datatable').each(function() {
        let colCount = $(this).find('th').length;
        let table = $(this);

        var opts = {
            order: [],
            info: false,
            paging: false,
            searching: false,
            scrollX: false,
            columnDefs: [],
        };

        if (!$(this).data('withoutcheckboxrow')) {
            opts.columnDefs.push({
                targets: 0,
                orderable: false,
            });

            opts.order.push([1, 'asc']);
        } else if ($(this).data('ordercolumn')) {
            opts.order.push([$(this).data('ordercolumn'), 'asc']);
        } else {
            opts.order.push([0, 'asc']);
        }

        if (!$(this).data('withoutactionrow')) {
            opts.columnDefs.push({
                targets: colCount - 1, // Actionrow!
                orderable: false,
            });
        }

        if (!$(this).data('disableresponsivescroll')) {
            opts.autoWidth = true;
            opts.scrollX = false;

            opts.initComplete = (settings, json) => {
                table.wrap("<div style='overflow:auto;width:100%;position:relative;'></div>");
            };
        }

        $(this).DataTable(opts);
    });
});

$(document).on('click', '.dataTables_wrapper .js-select-all', function(e) {
    e.preventDefault();

    let parent = $(this).closest('.dataTables_wrapper');
    let self = $(this).find('input[type="checkbox"]');
    let checkboxes = parent.find('tbody input[type="checkbox"]');
    let checked = self.prop('checked');

    self.prop('checked', !checked);

    checkboxes.each(function(i, element) {
        if (checked) {
            return $(this).prop('checked', false);
        }
        return $(this).prop('checked', true);
    });
});
