import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(zoomPlugin);

var priceHistory = {
    init: () => {
        $(document).on('click', '.pricehistory_open', function (e) {
            let articleNumber = $(this).data('articlenumber');

            priceHistory.open(articleNumber);
        });

        $(document).on('click', '.price-history__nav .nav-link', function (e) {
            e.preventDefault();

            $('.price-history__nav .nav-link').removeClass('active');
            $(this).addClass('active');

            let type = $(this).data('type');
            priceHistory.show(type);
        });
    },

    close: () => {
        priceHistory.hideLoader();

        $('#ajaxModal').modal('hide');
    },

    open: (articleNumber) => {
        $.get($('#product_stats_modal_get').val(), function (data) {
            $('#ajaxModal').html(data);
            $('#ajaxModal').modal('show');
            $('.chart-price #article_number').val(articleNumber);

            priceHistory.show('weekly');
        });
    },

    show: function (type) {
        let articleNumber = $('.chart-price #article_number').val();
        let priceHistoryEle = $('#price-history__' + type);

        if (priceHistoryEle.hasClass('loaded')) {
            $('.chart-price__table').hide();
            priceHistoryEle.show();
            priceHistory.hideLoader();
            return;
        }

        $('.chart-price__container canvas').hide();

        priceHistory.showLoader();
        $('.chart-price__error-container').hide();

        $.ajax({
            url: $('#product_stats_ajax_get_stats_get').val(),
            type: 'POST',
            data: {
                type: type,
                article_number: articleNumber,
            },
            dataType: 'json',
            success: (response) => {
                if (response.data.length == 0) {
                    priceHistory.hideLoader();
                    priceHistoryEle.addClass('loaded');
                    priceHistoryEle.show();

                    $('.chart-price__error-container').show();
                    return;
                }

                priceHistory.hideLoader();
                priceHistoryEle.addClass('loaded');
                priceHistoryEle.show();

                new Chart(document.getElementById('price-history__' + type), {
                    type: 'line',
                    data: {
                        datasets: [
                            {
                                label: response.unit,
                                data: response.data,
                                borderColor: '#e3000f',
                                backgroundColor: '#e3000f',
                            },
                        ],
                    },
                    options: {
                        responsive: true,
                        plugins: {
                            legend: {
                                display: false,
                            },
                        },
                        scales: {
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    // Include a dollar sign in the ticks
                                    callback: function (value, index, ticks) {
                                        return value + response.unit;
                                    },
                                },
                            },
                        },
                    },
                });
            },
            error: (response) => {
                console.error(response);
                alert('There was an error. Please try again later.');
            },
        });
    },

    showLoader: () => {
        $('.chart-price__box').hide();
        $('.product_stats_modal .chart-price__loader-container').css('display', 'flex');
    },

    hideLoader: () => {
        $('.chart-price__box').show();
        $('.product_stats_modal .chart-price__loader-container').css('display', 'none');
    },
};

priceHistory.init();

window.priceHistory = priceHistory;
