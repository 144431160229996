const print = options => {
    options = { element: false, url: false, title: 'FAPA', ...options };

    const { element, title, url } = options;

    if (!element && !url) return console.warn('Print - No options given');

    const Header = `<div class="print-header">
                <div class="print-title">
                    <h1>${title}</h1>
                </div>
                <div class="print-logo">
                <img src="/assets/logo.png">
                </div>
        </div>`;

    if (element) {
        const printContainer = $("<div class='print-this'></div>").append($(element).clone());

        return printContainer.printThis({
            importCSS: true,
            header: Header,
            debug: false,
            title: title,
        });
    }

    $.get(url, function(data) {
        let printContainer = $(data);

        printContainer.printThis({
            header: Header,
            debug: false,
            title: title,
        });
    });
};

export default print;
