jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    'numeric-comma-pre': function(a) {
        a = a.replace(/(<([^>]+)>)/gi, '');
        var x = a == '-' ? 0 : a.replace(/,/, '.');
        return parseFloat(x);
    },

    'numeric-comma-asc': function(a, b) {
        return a < b ? -1 : a > b ? 1 : 0;
    },

    'numeric-comma-desc': function(a, b) {
        return a < b ? 1 : a > b ? -1 : 0;
    },
});
