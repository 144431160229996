import Cookies from 'js-cookie';
import { convertYoutubeEmbedded } from './_helpers';

$('.details.datatable').on('init.dt', function() {
    $('.details.datatable tr').each(function() {
        var categoryid = $('.details.datatable').data('categoryid');
        var id = $(this).data('id');

        if (!id) return;

        if (Cookies.get('product_list_state_' + categoryid)) {
            var ids = Cookies.get('product_list_state_' + categoryid) ? Cookies.get('product_list_state_' + categoryid).split(',') : [];

            if (ids.indexOf('' + id) == -1) {
                $(this).hide();
            }
        }
    });

    _checkDetailListInfoBar();
});

convertYoutubeEmbedded({ element: $('iframe'), url: $('iframe').data('raw-url') });

$('.details.datatable').DataTable({
    order: [[1, 'asc']],
    info: false,
    paging: false,
    searching: false,
    scrollX: true,
    columnDefs: [
        {
            targets: $('.details.datatable th td').length - 1, // Actionrow!
            orderable: false,
        },
    ],
});

$(document).on('click', '.product_list_reset_selection', function(e) {
    e.preventDefault();
    var categoryid = $('.details.datatable').data('categoryid');
    Cookies.remove('product_list_state_' + categoryid);

    $('.details.datatable tr').show();

    _checkDetailListInfoBar();
});

function _checkDetailListInfoBar() {
    var categoryid = $('.details.datatable').data('categoryid');

    if (Cookies.get('product_list_state_' + categoryid)) {
        $('#message_list_filtered').show();
    } else {
        $('#message_list_filtered').hide();
    }
}
