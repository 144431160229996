if ($('#return_reequest_sample_dropzone').length > 0) {
    $('#return_reequest_sample_dropzone').dropzone({
        url: $('#order_ajax_upload_url').val(),
        maxFilesize: $('#_max_filesize').val(),
        paramName: 'files',
        parallelUploads: 5,
        maxFiles: 5,
        createImageThumbnails: false,
        //acceptedFiles: 'image/*,application/pdf',
        addRemoveLinks: true,
        dictDefaultMessage: $('#i18_dropzone_dictDefaultMessage').val(),
        dictFallbackText: $('#i18_dropzone_dictFallbackText').val(),
        dictFallbackMessage: $('#i18_dropzone_dictFallbackMessage').val(),
        dictFileTooBig: $('#i18_dropzone_dictFileTooBig').val(),
        dictInvalidFileType: $('#i18_dropzone_dictInvalidFileType').val(),
        dictResponseError: $('#i18_dropzone_dictResponseError').val(),
        dictCancelUpload: $('#i18_dropzone_dictCancelUpload').val(),
        dictUploadCanceled: $('#i18_dropzone_dictUploadCanceled').val(),
        dictCancelUploadConfirmation: $('#i18_dropzone_dictCancelUploadConfirmation').val(),
        dictRemoveFile: $('#i18_dropzone_dictRemoveFile').val(),
        dictRemoveFileConfirmation: $('#i18_dropzone_dictRemoveFileConfirmation').val(),
        dictMaxFilesExceeded: $('#i18_dropzone_dictMaxFilesExceeded').val(),
        init: function () {
            this.on('sending', function (e, xhr, formData) {
                formData.append('_token', $('#_token').val());
                formData.append('_upload_identifier', $('#upload_identifier').val());
            });
        },
    });
}

// Restricts input for the set of matched elements to the given inputFilter function.
(function ($) {
    $.fn.inputFilter = function (callback, errMsg) {
        return this.on('input keydown keyup mousedown mouseup select contextmenu drop focusout', function (e) {
            if (callback(this.value)) {
                // Accepted value
                if (['keydown', 'mousedown', 'focusout'].indexOf(e.type) >= 0) {
                    $(this).removeClass('input-error');
                    this.setCustomValidity('');
                }
                this.oldValue = this.value;
                this.oldSelectionStart = this.selectionStart;
                this.oldSelectionEnd = this.selectionEnd;
            } else if (this.hasOwnProperty('oldValue')) {
                // Rejected value - restore the previous one
                $(this).addClass('input-error');
                this.setCustomValidity(errMsg);
                this.reportValidity();
                this.value = this.oldValue;
                this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            } else {
                // Rejected value - nothing to restore
                this.value = '';
            }
        });
    };
})(jQuery);

$('.return-page__table .table-quantity input').inputFilter(function (value) {
    return /^\d*$/.test(value); // Allow digits only, using a RegExp
}, 'Only digits allowed');

// the selector will match all input controls of type :checkbox
// and attach a click event handler
$('.return-option input:checkbox').on('click', function () {
    // in the handler, 'this' refers to the box clicked on
    var $box = $(this);
    if ($box.is(':checked')) {
        // the name of the box is retrieved using the .attr() method
        // as it is assumed and expected to be immutable
        var group = "input:checkbox[name='" + $box.attr('name') + "']";
        // the checked state of the group/box on the other hand will change
        // and the current value is retrieved using .prop() method
        $(group).prop('checked', false);
        $box.prop('checked', true);
    } else {
        $box.prop('checked', false);
    }
});

$('.return-process-back-page').on('click', function () {
    var block = $('.return-page__content.show').removeClass('show');
    $('.return-page__content.hide').removeClass('hide').addClass('show');
    block.addClass('hide');
});

$('.return-process-next-page').on('click', function () {
    //each row
    let rows_are_correct = [];
    let row_is_filled = false;
    let set = $('.return-page__table > tbody > tr:not(:first)');
    set.each(function (index, tr) {
        let pastcell_empty = null;
        //all inputs of row
        let inputs = $(tr).find('.return-page__input');
        var length = inputs.length;
        // each input
        inputs.each(function (index, input) {
            // prüfe ob es leer ist
            if (input.value.trim() == '' || input.value == 0) {
                //wenn die letzte zelle nicht gefüllt war
                if (pastcell_empty !== true) {
                    pastcell_empty = false;
                    //prüfe ob letzter eintrag
                    if (index === length - 1) {
                        rows_are_correct.push(true);
                    }
                    //wenn die letzte zelle gefüllt war
                } else {
                    console.log(input.value, 'tst2');
                    rows_are_correct.push(false);
                    return false; //break
                }
                // prüfe ob es nicht leer ist
            } else if (input.value.trim() != '' && input.value != 0) {
                //wenn die letzte zelle nicht leer war
                if (pastcell_empty !== false) {
                    pastcell_empty = true;
                    //prüfe ob letzter eintrag
                    if (index === length - 1) {
                        rows_are_correct.push(true);
                        row_is_filled = true;
                    }
                    //wenn die letzte zelle leer war
                } else {
                    rows_are_correct.push(false);
                    return false; //break
                }
            }
        });
    });
    console.log(rows_are_correct);
    let result = rows_are_correct.every(Boolean);

    if (result && row_is_filled) {
        var block = $('.return-page__content.show').removeClass('show');
        $('.return-page__content.hide').removeClass('hide').addClass('show');
        block.addClass('hide');
        $('.return-page__warning').addClass('d-none');
    } else {
        $('.return-page__warning').removeClass('d-none');
    }
});
