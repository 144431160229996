
const offerProducts = {
    initUpload: function() {
        if ($('.offers.dropzone').length > 0) {
            $('.offers.dropzone').dropzone({
                url: $('#offers_ajax_upload_url').val(),
                maxFilesize: $('#_max_filesize').val(),
                paramName: 'files',
                parallelUploads: 5,
                maxFiles: 5,
                createImageThumbnails: false,
                //acceptedFiles: 'image/*,application/pdf',
                addRemoveLinks: true,
                dictDefaultMessage: $('#i18_dropzone_dictDefaultMessage').val(),
                dictFallbackText: $('#i18_dropzone_dictFallbackText').val(),
                dictFallbackMessage: $('#i18_dropzone_dictFallbackMessage').val(),
                dictFileTooBig: $('#i18_dropzone_dictFileTooBig').val(),
                dictInvalidFileType: $('#i18_dropzone_dictInvalidFileType').val(),
                dictResponseError: $('#i18_dropzone_dictResponseError').val(),
                dictCancelUpload: $('#i18_dropzone_dictCancelUpload').val(),
                dictUploadCanceled: $('#i18_dropzone_dictUploadCanceled').val(),
                dictCancelUploadConfirmation: $('#i18_dropzone_dictCancelUploadConfirmation').val(),
                dictRemoveFile: $('#i18_dropzone_dictRemoveFile').val(),
                dictRemoveFileConfirmation: $('#i18_dropzone_dictRemoveFileConfirmation').val(),
                dictMaxFilesExceeded: $('#i18_dropzone_dictMaxFilesExceeded').val(),
                init: function() {
                    this.on('sending', function(e, xhr, formData) {
                        formData.append('_token', $('#_token').val());
                        formData.append('_upload_identifier', $('#upload_identifier').val());
                    });
                },
            });
        }
    }
};

$(document).on('click', '.offers_inquery', function(e) {
    e.preventDefault();

    let ids = [$(this).data('id')];

    $.ajax($('#offers_ajax_inquery_url').val(), {
        method: 'POST',
        data: {
            _token: $('#_token').val(),
            ids: ids.join(','),
        },
        success: function(data) {
            $('#ajaxModal').html(data);
            $('#ajaxModal').modal('show');
            offerProducts.initUpload();
        },
    });
});

$(document).on('click', '.offers_inquery_multiple', function(e) {
    e.preventDefault();
    var ids = [];

    $('.maincontent')
        .find('td input[type=checkbox]:checked')
        .each(function() {
            var id = $(this)
                .closest('tr')
                .data('id');

            if (id) {
                ids.push(id);
            }
        });

    if (ids.length == 0) {
        alert($('#i18_no_selection').val());
        return;
    }

    $.ajax($('#offers_ajax_inquery_url').val(), {
        method: 'POST',
        data: {
            _token: $('#_token').val(),
            ids: ids.join(','),
        },
        success: function(data) {
            $('#ajaxModal').html(data);
            $('#ajaxModal').modal('show');
            offerProducts.initUpload();
        },
    });
});

$(document).on('click', '.offers_inquery_submit', function(e) {
    var self = $(this);
    e.preventDefault();

    if ($('#offers_inquery_form').valid()) {
        let data = $('#offers_inquery_form').serializeArray();
        data.push({
            name: '_token',
            value: $('#_token').val(),
        });

        $.post($('#offers_ajax_inquery_submit_url').val(), data, function() {
            $('#ajaxModal').modal('hide');

            setTimeout(function() {
                $('.alert')
                    .hide()
                    .html($('#i18n_offers_ajax_inquery_success').val())
                    .show();
            }, 500);
        });
        return;
    }
});
