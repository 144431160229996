const init = () => {
    $(document).on('click', '.openInfo', function(e) {
        e.preventDefault();

        var target = $(this).attr('href');

        $.get(target, function(data) {
            $('#ajaxModal').html(data);
            $('#ajaxModal').modal('show');

            
        });

        return false;
    });

    function track(key, callback) {
        $.ajax('/track',
            {
                'method': 'post',
                'data': {
                    _token: $('#_token').val(),
                    'key': key
                },
                complete: function() {
                    callback();
                }
            }
        );
    }

    $(document).on('click', '.track-dashboard', function(e) {
        e.preventDefault();
        var url = $(this).attr('href');
        track("dashboard-button-click", () => { window.location.href = url; });
    });
};

init();