$(document).on('click', '.request_sample', function (e) {
    const id = $(this).attr('data-id');
    if (!id) return;

    e.preventDefault();

    var target = $('#product_ajax_request_sample').val();

    $.post(
        target,
        {
            id: id,
            _token: $('#_token').val(),
        },
        function (data) {
            $('#ajaxModal').html(data);
            $('#ajaxModal').modal('show');
            if ($('.sample.dropzone').length > 0) {
                $('.sample.dropzone').dropzone({
                    url: $('#order_ajax_upload_url').val(),
                    maxFilesize: $('#_max_filesize').val(),
                    paramName: 'files',
                    parallelUploads: 5,
                    maxFiles: 5,
                    createImageThumbnails: false,
                    //acceptedFiles: 'image/*,application/pdf',
                    addRemoveLinks: true,
                    dictDefaultMessage: $('#i18_dropzone_dictDefaultMessage').val(),
                    dictFallbackText: $('#i18_dropzone_dictFallbackText').val(),
                    dictFallbackMessage: $('#i18_dropzone_dictFallbackMessage').val(),
                    dictFileTooBig: $('#i18_dropzone_dictFileTooBig').val(),
                    dictInvalidFileType: $('#i18_dropzone_dictInvalidFileType').val(),
                    dictResponseError: $('#i18_dropzone_dictResponseError').val(),
                    dictCancelUpload: $('#i18_dropzone_dictCancelUpload').val(),
                    dictUploadCanceled: $('#i18_dropzone_dictUploadCanceled').val(),
                    dictCancelUploadConfirmation: $('#i18_dropzone_dictCancelUploadConfirmation').val(),
                    dictRemoveFile: $('#i18_dropzone_dictRemoveFile').val(),
                    dictRemoveFileConfirmation: $('#i18_dropzone_dictRemoveFileConfirmation').val(),
                    dictMaxFilesExceeded: $('#i18_dropzone_dictMaxFilesExceeded').val(),
                    init: function () {
                        this.on('sending', function (e, xhr, formData) {
                            formData.append('_token', $('#_token').val());
                            formData.append('_upload_identifier', $('#upload_identifier').val());
                        });
                    },
                });
            }
        }
    );
});

$(document).on('click', '.product_sample_submit_btn', function (e) {
    var self = $(this);
    e.preventDefault();

    if ($('#product_sample_form').valid()) {
        let data = $('#product_sample_form').serializeArray();
        data.push({
            name: '_token',
            value: $('#_token').val(),
        });

        data.push({
            name: 'order_source',
            value: $('.article_id').val(),
        });

        $.post($('#ajax_sample_submit_url').val(), data, function () {
            $('#ajaxModal').modal('hide');

            setTimeout(function () {
                $('.alert').hide().html($('#i18n_contacts_ajax_submit_success').val()).show();

                $('#no_bookmarks').show();
                $('#contact_area').hide();
                $('#products').hide();

                if ($('#is_rating_disabled').val() == '0') {
                    window.showRating();
                }
            }, 500);
        });
        return;
    } else {
        $('#sample-warning').removeClass('d-none');
    }
});
