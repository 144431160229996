import print from './_print';
import orderProducts from './_order';
import {getAllCheckedProductIds, getAllProductIds, getAllCheckedProductIdsAndCounts} from './_list';

const init = () => {
    if (!$('body').hasClass('page-favorites')) return;

    let select = $('select[name=favorites_control]');
    select.each(function(i, item) {
        $(item).select2({
            selectOnClose: false,
        });

        $(item).on('select2:select', function(e) {
            itemSelected($(item));
        });
    });
};

const itemSelected = element => {
    if (!element) return;
    let action = element.val();
    let type = element.data('type'); // can be entire list or selected products

    console.log('Selected Action', action, ' with ', type);

    /**
     * Hier kommt dann die Logik hin die bei dem jeweiligen
     * Klick ausgeführt werden soll. Value ist dann gleich
     * das Event/Function welche getriggert werden soll
     */
    eventHandler(action, type);
    element.select2('val', 0);
};

const eventHandler = (event, type) => {
    if (!event) return;

    switch (event) {
        case 'enquiries':
            return enquiriesProducts(type);

        case 'orderProducts':
            return orderProducts(type);

        case 'printProducts':
            return printProducts(type);

        case 'printList':
            return printList();

        default:
            return false;
    }
};

const printProducts = type => {
    if (type == 'selection' && getAllCheckedProductIds() <= 0) {
        alert($('#i18_no_selection').val());
        return;
    }

    var ids = [];

    if (type == 'selection') {
        ids = getAllCheckedProductIds();
    } else {
        ids = getAllProductIds();
    }

    return print({
        url: $('#favorites_print_products_get_url').val() + '/' + ids.join(','),
    });
};

const printList = () => {
    return print({
        url: $('#favorites_print_list_get_url').val(),
    });
};

const enquiriesProducts = type => {
    if (type == 'selection' && getAllCheckedProductIds() <= 0) {
        alert($('#i18_no_selection').val());
        return;
    }

    $.post(
        $('#favorites_enqueries_form_get_url').val(),
        {
            _token: $('#_token').val(),
            type: type,
            ids: getAllCheckedProductIds().join(','),
        },
        function(data) {
            $('#ajaxModal').html(data);
            $('#ajaxModal').modal('show');
        }
    );
};

$(document).on('click', '.favorites_enquiries_submit_btn', function(e) {
    var self = $(this);
    e.preventDefault();

    if ($('#favorites_form').valid()) {
        let data = $('#favorites_form').serializeArray();
        data.push({
            name: '_token',
            value: $('#_token').val(),
        });

        data.push({
            name: 'product_ids',
            value: getAllCheckedProductIds().join(','),
        });

        $.post($('#favorites_enqueries_submuit_post_url').val(), data, function() {
            $('#ajaxModal').modal('hide');

            setTimeout(function() {
                $('.alert')
                    .hide()
                    .html($('#i18n_contacts_ajax_submit_success').val())
                    .show();
            }, 500);
        });
        return;
    }
});

$(document).ready(function() {
    $("input.js-bookmark_amount").each(function(k, v) {
        $(v).on('keyup change', function() {
            if($(v).val() > 0) {
                $("input[name='product_selection_"+$(v).attr("data-id")+"']").prop("checked", true);
            } else {
                $("input[name='product_selection_"+$(v).attr("data-id")+"']").prop("checked", false);
            }
        });

        $("input[name='product_selection_"+$(v).attr("data-id")+"']").on("change", function(event) {
            let quantity = $("input.js-bookmark_amount[data-id='"+$(this).attr("data-id")+"']");
            if($(this).is(":checked") && quantity.val() == "0") {
                quantity.val("1");
            }
            if(!$(this).is(":checked")) {
                quantity.val("0");
            }
        });
    });
});

init();
