import print from './_print';
import { convertYoutubeEmbedded, lightSliderRefresh, openNavTab, pauseVideoOnTabChange } from './_helpers';

$(document).on('init.dt', '.products.datatable', function () {
    _checkProductListInfoBar();
});

$(document).on('change', '.products.datatable input[type=checkbox]', function (e) {
    /* var state = $(this).is(':checked');
	var categoryid = $('.products.datatable').data('categoryid');
	var id = $(this)
		.closest('tr')
		.data('id');

	var ids = Cookies.get('product_list_state_' + categoryid) ? Cookies.get('product_list_state_' + categoryid).split(',') : [];

	if (!state) {
		console.log('Removing Cookie product_list_state_' + categoryid);

		if (ids.length <= 1) {
			Cookies.remove('product_list_state_' + categoryid);
		} else {
			delete ids[ids.indexOf('' + id)];

			Cookies.set('product_list_state_' + categoryid, ids.join(','));
		}

		_checkProductListInfoBar();
		return;
	}

	if (ids.indexOf('' + id) == -1) {
		ids.push(id);
	}

	console.log('Adding Cookie product_list_state_' + categoryid);

	Cookies.set('product_list_state_' + categoryid, ids.join(','));

	_checkProductListInfoBar(); */
});

$(document).on('click', '.products tr', function (e) {
    if ($(e.target).prop('tagName') != 'TR' && $(e.target).prop('tagName') != 'TD') {
        return;
    }

    if ($(this).data('target')) window.location = $(this).data('target');
});

function _checkProductListInfoBar() {
    if ($('.products.datatable input[type=checkbox]:checked').length > 0) {
        $('.info_btns').show();
    } else {
        $('.info_btns').hide();
    }

    $('.products tr').each(function () {
        if ($(this).find('input[type=checkbox]').is(':checked')) {
            if (!$(this).hasClass('active')) {
                $(this).addClass('active');
            }
        } else {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
            }
        }
    });
}

$(document).on('click', '.products_filter_clear_btn', function (e) {
    e.preventDefault();

    window.location = window.location.href.split('?')[0];
});

$(document).on('click', '.products-results a.more_infos', function (e) {
    e.preventDefault();

    var target = $('#categories_ajax_info').val();

    $.post(
        target,
        {
            id: $(this).data('id'),
            _token: $('#_token').val(),
        },
        function (data) {
            $('#ajaxModal').html(data);
            $('#ajaxModal').modal('show');

            $('.rating').barrating({
                theme: 'bootstrap-stars',
            });

            var button = $('#ajaxModal').find('.js-print');

            // button.on('click', function (e) {
            //     e.preventDefault();
            //     print({
            //         url: $(this).data('url'),
            //         title: $('#ajaxModal').find('.modal-title').text(),
            //     });
            // });

            pauseVideoOnTabChange();
            convertYoutubeEmbedded({ element: $('iframe'), url: $('iframe').data('raw-url') });

            openNavTab({ target: 1 });
        }
    );

    return false;
});

$(document).on('change paste keyup keydown blur keypress', '.js-check-inventory', function () {
    return;
    let stateSpan = $(this).parent('td').find('.inventory_state');

    if (!stateSpan.length) {
        return; // Not enabled!
    }

    let cur = $(this).val();
    let inventory = $(this).data('inventory');

    if (cur > inventory || inventory == 0) {
        //stateSpan.addClass('orange');
        //stateSpan.removeClass('green');

        if (!$('.bs-popover-left.show').length) {
            stateSpan.popover('show');
        }
    } else {
        //stateSpan.removeClass('orange');
        //stateSpan.addClass('green');

        if ($('.bs-popover-left.show').length) {
            stateSpan.popover('hide');
        }
    }
});

window._reloadInventoryState = function () {
    $('.inventory_state').each(function () {
        let self = $(this);

        self.popover({
            html: true,
            trigger: 'click',
            placement: 'left',
            content: function () {
                $('[data-original-title]').popover('hide');
                return self.data('tooltip');
            },
            title: function () {
                return '';
            },
        });
    });
};

window._reloadInventoryState();

$('html').on('click', function (e) {
    if (typeof $(e.target).data('original-title') == 'undefined' && $(e.target).attr('type') != 'number' && !$(e.target).hasClass('popover-body') && !$(e.target).parents().is('.popover.in')) {
        $('[data-original-title]').popover('hide');
    }
});

$(document).on('click', '*[data-article-id]', function (e) {
    const id = $(this).data('article-id');
    if (!id) return;

    e.preventDefault();

    var target = $('#categories_ajax_article').val();

    $.post(
        target,
        {
            id: id,
            _token: $('#_token').val(),
        },
        function (data) {
            $('#ajaxModal').html(data);
            $('#ajaxModal').modal('show');

            lightSliderRefresh();
            pauseVideoOnTabChange();
            window._reloadInventoryState();
            convertYoutubeEmbedded({ element: $('iframe'), url: $('iframe').data('raw-url') });

            openNavTab({ target: 1 });
        }
    );
});

$(document).on('click', '.js-print', function (e) {
    let button = $(this);
    if (!button || !button.length) return;

    let printArea = $('.product-content');

    button.on(
        'click',
        print({
            url: $(this).data('url'),
            title: printArea.find('h2').text(),
        })
    );
});

$(document).on('change', '.products.datatable input[type=checkbox]', function (e) {
    if ($('.result-item__products').data('type') == 'filter') {
        const state = $(this).is(':checked');

        if (state === true) {
            $(this).closest('tr').find('.js-bookmark_amount').val('1');
        } else {
            $(this).closest('tr').find('.js-bookmark_amount').val('0');
        }
    }
});

$(document).on('change', '.js-bookmark_amount', function (e) {
    if ($('.result-item__products').data('type') == 'filter') {
        const val = $(this)[0].value;
        if (val > 0) {
            $(this).closest('tr').find('.custom-checkout input').prop('checked', true);
        } else {
            $(this).closest('tr').find('.custom-checkout input').prop('checked', false);
        }
    }
});
