import Navigation from './_sidemenu_nav';

const Sidemenu = $('.sidemenu'),
    Toggle = $('.toggle-sidemenu'),
    Close = $('.close-sidemenu'),
    BodyClass = 'active-sidemenu',
    Body = $('body'),
    BackdropClass = 'sidemenu__backdrop';

const HideSidemenu = () => {
    Body.removeClass(BodyClass);
    $(`.${BackdropClass}`).remove();
};

const ToggleSidemenu = () => {
    Body.addClass(BodyClass);

    Body.append(`<div class="${BackdropClass}"></div>`);
    $(`.${BackdropClass}`).on('click', HideSidemenu);
};

const Init = () => {
    Toggle.on('click', function(e) {
        e.stopPropagation();
        ToggleSidemenu();
    });

    Close.on('click', function(e) {
        e.stopPropagation();
        HideSidemenu();
    });

    Navigation;
};

Init();
