function feedbackContact() {
    this.init = function(options) {
        window.feedbackContact = this;
        this.config = options;

        $(document).ready(this.onDocumentReady);
    };

    this.onDocumentReady = function() {
        window.feedbackContact.show(1600);

        $(".feedback-icon").on("click", function() {
            window.feedbackContact.hide(0, function() {
                $("#feedback-form").show();
                $(".feedbackcontact .buttons").hide();
                window.feedbackContact.show();
            });
        });
        $(".contact-icon").on("click", function() {
            window.feedbackContact.hide(0, function() {
                $("#contact-form").show();
                $(".feedbackcontact .buttons").hide();
                window.feedbackContact.show();
            });
        });

        $(".feedbackcontact-form .close").on("click", function() {
            let formDiv = $(this).parent();
            window.feedbackContact.hide(0, function() {
                formDiv.hide();
                $(".feedbackcontact .buttons").show();
                window.feedbackContact.show();
            });
        });

        $(".feedbackcontact-form form").on("submit", function(e) {
            e.preventDefault();
            $(this).find("button[type='submit']").prop("disabled", true);
            let form = this;
            let feedbackContact = $(form).closest(".feedbackcontact-form");
            let data = $(form).serializeArray();
            data.push({
                name: '_token',
                value: $('#_token').val(),
            });
            $(form).find(".invalid-feedback").hide();
            
            $.ajax({
                url: $(this).attr("action"),
                method: "post",
                success: function(data) {
                    $(feedbackContact).find('.content').fadeOut(400, "swing", function() {
                        $(feedbackContact).find('.success').fadeIn();
                    });
                },
                data: data,
                error: function(data) {
                    if(data.status == 422) {
                        $.each(data.responseJSON.errors, function(k, v) {
                            if($(form).find("."+k+"-error").length > 0) {
                                $(form).find("."+k+"-error").html(v[0]);
                                $(form).find("."+k+"-error").fadeIn();
                            }
                        });
                    } else {
                        alert("Ein unbekannter Fehler ist aufgetreten.");
                    }
                },
                complete: function() {
                    $(form).find("button[type='submit']").prop("disabled", false);
                }
            });
        });

    };

    this.show = function(delay=0, clbk) {
        $('.feedbackcontact').delay(delay).animate({ right: '25px' }, 200, clbk);
    };
    this.hide = function(delay=0, clbk) {
        $('.feedbackcontact').delay(delay).animate({ right: '-400px' }, 200, clbk);
    };
}

(function() {
    var fc = new feedbackContact();

    fc.init({
        
    });
})();