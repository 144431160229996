const Menu = $('.sidemenu__list');
const Items = Menu.find('.sidemenu__list--item');
const OpenClass = 'open';

const closeAllItems = () => {
    Items.each(function() {
        $(this).removeClass(OpenClass);
    });
};

const OpenItem = item => {
    closeAllItems();
    item.toggleClass(OpenClass);
};

const Init = () => {
    if (!Menu || !Items) return;

    Items.on('click', function(e) {
        closeAllItems();

        let element = $(this);
        if (!element.find('.sidemenu__sublist') || !element.find('.sidemenu__sublist').length) return;
        e.preventDefault();

        OpenItem(element);
    });
};

export default Init();
