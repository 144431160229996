const Sidebar = $('.sidebar');

const Collapse = () => {
    if (!Sidebar || !Sidebar.length) return;

    Sidebar.each(function() {
        let element = $(this);

        let trigger = element.find('[sidebar-trigger]');

        trigger.on('click', function(e) {
            let bool = element.attr('sidebar-collapse') === 'true';
            element.attr('sidebar-collapse', () => !bool);
        });
    });
};

Collapse();
