var languageDE = require('./dataTables_german');
var languageFR = require('./dataTables_french');
$('.datatable__offers').each(function () {
    var opts = {
        order: [[0, 'desc']],
        info: false,
        pageLength: 8,
        lengthMenu: [8, 15, 25, 50, 100],
    };

    if($(this).hasClass("stockchange-table")) {
        opts["pageLength"] = 50;
    }

    if ($('#language').val() == 'de') {
        opts["language"] = languageDE.language;
    }
    if ($('#language').val() == 'fr') {
        opts["language"] = languageFR.language;
    }

    $(this).DataTable(opts);
});

$(document).on('click', '.datatable__eye', function () {
    const tr = $(this).closest('tr').html();
    const thead = $('.datatable__offers thead').html();

    var data = '<div class="modal-dialog"><div class="modal-content"><div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div><div class="modal-body"><table class="popup-modal__table"> <thead>' +
            thead +
            '</thead><tbody>' +
            tr +
            '</tbody></table></div></div>    </div><'
    ;
    $('#detailModal').html(data)
    $('#detailModal').modal('show');


    $('.modal table thead tr th').removeClass('datatable__display');

    $('.modal table tbody tr td').removeClass('datatable__display');
});
