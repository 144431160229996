$(document).on('click', '.contact_submit_btn', function(e) {
    var self = $(this);
    e.preventDefault();

    if ($('#contact_form').valid()) {
        let data = $('#contact_form').serializeArray();
        data.push({
            name: '_token',
            value: $('#_token').val(),
        });

        $.post($('#contact_ajax_submit_url').val(), data, function() {
            $('#ajaxModal').modal('hide');

            setTimeout(function() {
                alert($('#i18n_contacts_ajax_submit_success').val());

                if ($('#is_rating_disabled').val() == '0') {
                    window.showRating();
                }
            }, 500);
        });
        return;
    }
});
