$(document).on('click', '.rating_submit_btn', function(e) {
    var self = $(this);
    e.preventDefault();

    if ($('#rating_form').valid()) {
        let data = $('#rating_form').serializeArray();
        data.push({
            name: '_token',
            value: $('#_token').val(),
        });

        $.post($('#rating_ajax_submit_url').val(), data, function() {
            $('#ajaxModal').modal('hide');

            setTimeout(function() {
                alert($('#i18n_rating_ajax_submit_success').val());
            }, 500);
        });
        return;
    }
});

window.showRating = function() {
    var target = $('#rating_ajax_show_url').val();

    $.get(target, function(data) {
        $('#ajaxModal').html(data);
        $('#ajaxModal').modal('show');

        $('.rating').barrating({
            theme: 'bootstrap-stars',
        });
    });

    return false;
};

$(document).on('click', '.show_rating', window.showRating);
