
const invoices = {
    initUpload: function() {
        if ($('.invoices.dropzone').length > 0) {
            $('.invoices.dropzone').dropzone({
                url: $('#invoices_ajax_upload_url').val(),
                maxFilesize: $('#_max_filesize').val(),
                paramName: 'files',
                parallelUploads: 5,
                maxFiles: 5,
                createImageThumbnails: false,
                //acceptedFiles: 'image/*,application/pdf',
                addRemoveLinks: true,
                dictDefaultMessage: $('#i18_dropzone_dictDefaultMessage').val(),
                dictFallbackText: $('#i18_dropzone_dictFallbackText').val(),
                dictFallbackMessage: $('#i18_dropzone_dictFallbackMessage').val(),
                dictFileTooBig: $('#i18_dropzone_dictFileTooBig').val(),
                dictInvalidFileType: $('#i18_dropzone_dictInvalidFileType').val(),
                dictResponseError: $('#i18_dropzone_dictResponseError').val(),
                dictCancelUpload: $('#i18_dropzone_dictCancelUpload').val(),
                dictUploadCanceled: $('#i18_dropzone_dictUploadCanceled').val(),
                dictCancelUploadConfirmation: $('#i18_dropzone_dictCancelUploadConfirmation').val(),
                dictRemoveFile: $('#i18_dropzone_dictRemoveFile').val(),
                dictRemoveFileConfirmation: $('#i18_dropzone_dictRemoveFileConfirmation').val(),
                dictMaxFilesExceeded: $('#i18_dropzone_dictMaxFilesExceeded').val(),
                init: function() {
                    this.on('sending', function(e, xhr, formData) {
                        formData.append('_token', $('#_token').val());
                        formData.append('_upload_identifier', $('#upload_identifier').val());
                    });
                },
            });
        }
    },
    initDetailModal: function() {
        $('body').append(
            '<div class="modal detailmodal hide fade popup-modal"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div><div class="modal-body"></div></div>    </div></div>'
        );
    }
};

var languageDE = require('./dataTables_german');
var languageFR = require('./dataTables_french');
$('.datatable__invoices').each(function () {
    var opts = {
        order: [[2, 'desc']],
        info: false,
        pageLength: 8,
        lengthMenu: [8, 15, 25, 50],
    };
    if ($('#language').val() == 'de') {
        opts = {
            order: [[2, 'desc']],
            info: false,
            pageLength: 8,
            lengthMenu: [8, 15, 25, 50],
            language: languageDE.language,
        };
    }
    if ($('#language').val() == 'fr') {
        opts = {
            order: [[2, 'desc']],
            info: false,
            pageLength: 8,
            lengthMenu: [8, 15, 25, 50],
            language: languageFR.language,
        };
    }
    opts.ajax = {
        url: $('#invoices_ajax_table').val()
    };
    opts.dataSrc = 'data';
    opts.columns = [
        { data: "checkbox" },
        { data: "invoice_number" },
        { data: "invoice_date_formatted" },
        { data: "your_sign" },
        { data: "article_name" },
        { data: "amount" },
        { data: "price" },
        { data: "reorder" },
        { data: "detailbutton" }
    ];
    opts.columnDefs = [
        {
            targets: [0,7,8],
            className: 'modal-hidden'
        }
    ];
    opts.serverSide = true;

    $(this).DataTable(opts);
});


$(document).on('click', '.datatable__invoices .datatable__details', function () {
    const tr = $(this).closest('tr').html();
    const thead = $('.datatable__invoices thead').html();
    $.ajax($('#invoices_ajax_details').val(), {
        method: 'POST',
        data: {
            _token: $('#_token').val(),
            id: $(this).attr('data-id')
        },
        success: function(data) {
            $('#detailModal').html(data)
            $('#detailModal').modal('show');
        }
    });

});


$(document).on('click', '.invoices_inquery', function(e) {
    e.preventDefault();

    let ids = [$(this).data('id')];
    let qtys = [$("input[name='call_of_quantity'][data-id='"+$(this).data('id')+"']").val()];

    $.ajax($('#invoices_ajax_inquery_url').val(), {
        method: 'POST',
        data: {
            _token: $('#_token').val(),
            ids: ids.join(','),
            qtys: qtys.join(','),
        },
        success: function(data) {
            $('#ajaxModal').html(data);
            $('#ajaxModal').modal('show');
            invoices.initUpload();
        },
    });
});

$(document).on('click', '.invoices_inquery_multiple', function(e) {
    e.preventDefault();
    var ids = [];
    var qtys = [];

    $('.maincontent')
        .find('td input[type=checkbox]:checked')
        .each(function() {
            var id = $(this)
                .data('id');

            if (id) {
                ids.push(id);
                var qty = $("input[name='call_of_quantity'][data-id='"+id+"']").val();
                if(qty <= 0) {
                    alert($('#i18_no_quantity').val());
                    return;
                }
                qtys.push(qty);
            }
        });

    if (ids.length == 0) {
        alert($('#i18_no_selection').val());
        return;
    }

    $.ajax($('#invoices_ajax_inquery_url').val(), {
        method: 'POST',
        data: {
            _token: $('#_token').val(),
            ids: ids.join(','),
            qtys: qtys.join(','),
        },
        success: function(data) {
            $('#ajaxModal').html(data);
            $('#ajaxModal').modal('show');
            invoices.initUpload();
        },
    });
});

$(document).on('click', '.invoices_inquery_submit', function(e) {
    var self = $(this);
    e.preventDefault();

    if ($('#invoices_inquery_form').valid()) {
        let data = $('#invoices_inquery_form').serializeArray();
        data.push({
            name: '_token',
            value: $('#_token').val(),
        });

        $.post($('#invoices_ajax_inquery_submit_url').val(), data, function() {
            $('#ajaxModal').modal('hide');

            setTimeout(function() {
                $('.alert')
                    .hide()
                    .html($('#i18n_offers_ajax_inquery_success').val())
                    .show();
            }, 500);
        });
        return;
    }
});
