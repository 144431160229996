/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import { openNavTab, pauseVideoOnTabChange } from './_helpers';
import 'select2';

require('./bootstrap');
require('./bootstrap3-typeahead');
require('./autocomplete');
require('./vendor/datatables-sorting-numeric-comma');

require('./vendor/printThis');
window.Bloodhound = require('./bloodhound');
require('./_navigation');
require('./_auth');
require('./_tracking');

require('./_order');
require('./_bookmarks');
require('./_products');
require('./_product_filter');
require('./_default_datatables');
require('./_details');
require('./_contact');
require('./_rating');
require('./_slider');
require('./_search');
require('./_sidebar');
require('./_sidemenu');
require('./_download');
require('./_print');
require('./_bookmarks_controls');
require('./_offers');
require('./_revenue');
require('./_blanket_positions');
require('./_consignment_store');
require('./_invoices');
require('./_favorites');

require('./_eprivacy');
require('./_feedback_contact');

require('./_customer_orders');

require('./_csv_export');

require('./_price_history');
require('./_request_sample');
require('./_return');

$('body').hide();

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

$(function () {
    $('[data-toggle="tooltip"]').tooltip();

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('input[name=_token]').val(),
        },
    });
});

// https://www.npmjs.com/package/lightslider
$('.rating').barrating({
    theme: 'bootstrap-stars',
});

$(document).on('click', '[data-toggle="lightbox"]', function (event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});

$(document).on('click touchstart', '.carousel-item, .carousel-item *', function (e) {
    if ($(this).data('target')) {
        window.location = $(this).data('target');
    }
});

// GENERIC MODAL
$(document).on('hidden', '#ajaxModal', function () {
    $('#modal').removeData('modal');
});

$(document).on('click', 'a[data-toggle=modal]', function () {
    if ($(this).data('disable-generic-handler')) return;
    var target = $(this).attr('href');

    $.get(target, function (data) {
        $('#ajaxModal').html(data);
        $('#ajaxModal').modal('show');
    });

    return false;
});

$('.carousel .carousel-inner').swipe({
    swipeLeft: function (event, direction, distance, duration, fingerCount) {
        $('.carousel').carousel('next');
    },
    swipeRight: function () {
        $('.carousel').carousel('prev');
    },
    threshold: 0,
    tap: function (event, target) {
        window.location = $(this).find('.carousel-item.active a').attr('href');
    },
    excludedElements: 'label, button, input, select, textarea, .noSwipe',
});

$('.carousel').swipe({
    swipe: function (event, direction, distance, duration, fingerCount, fingerData) {
        if (direction == 'left') $(this).carousel('next');
        if (direction == 'right') $(this).carousel('prev');
    },
    allowPageScroll: 'vertical',
});

$('.carousel .carousel-control-prev').on('click', function (e) {
    e.preventDefault();
    $('.carousel').carousel('prev');
});

$('.carousel .carousel-control-next').on('click', function (e) {
    e.preventDefault();
    $('.carousel').carousel('next');
});

$('.js-to-top').on('click', function () {
    $(window).scrollTop({ top: 0, behavior: 'smooth' });
});

$(document).on('click', '[data-link]', function (e) {
    let self = $(this);
    e.preventDefault();

    let link = self.data('link');
    if (!link || !link.length) return;

    window.location.href = link;
});

pauseVideoOnTabChange();

setTimeout(function () {
    $('body').show();
}, 500);

openNavTab({
    target: 1,
});

$(function () {
    $('.lightSlider').lightSlider({
        gallery: true,
        item: 1,
        loop: true,
        slideMargin: 0,
        thumbItem: 9,

        onSliderLoad: function (e) {
            let fallback = $('body').find(`.fallback[data-fallback-id="${$(e).data('fallback-target-id')}"]`);

            setTimeout(function () {
                if (fallback.length) {
                    fallback.fadeOut('slow', () => {
                        fallback.remove();
                    });
                }

                $(window).trigger('resize');
            }, 1000);
        },
    });
});
$(document).on('click', '.price_request', function (e) {
    e.preventDefault();
    let articleNumber = $(this).data('article_number');
    let count = $(this).closest('tr').find('input[type="number"]').val();

    if (count <= 0) {
        count = 1;
    }

    window.enquiriesProducts('single', articleNumber, count);
});
