$(document).on('click', '#csv-export-request', function (e) {
    e.preventDefault();
    $(this).prop('disabled', true);
    $.ajax($('#portal_csv_export_ajax-request_get').val(), {
        type: 'GET',

        success: function (result) {
            document.location.href = $('#portal_csv_export_index_get').val();
        },
        error: function (result) {
            console.error(result);
            alert("Ein unbekannter Fehler ist aufgetreten");
        },
    });
});
