
const getAllProductIds = () => {
    let checkboxes = $('body').find('input[type=checkbox][name^="product_selection"]');
    let ids = [];

    checkboxes.each(function(index, checkbox) {
        let id = $(checkbox).data('id');
        if (!id) return;

        ids.push(id);
    });

    return ids;
};
const getAllCheckedProductIds = () => {
    let checkboxes = $('body').find('input[type=checkbox][name^="product_selection"]:checked');
    let ids = [];

    checkboxes.each(function(index, checkbox) {
        let id = $(checkbox).data('id');
        if (!id) return;

        ids.push(id);
    });

    return ids;
};

const getAllCheckedProductIdsAndCounts = () => {
    let checkboxes = $('body').find('input[type=checkbox][name^="product_selection"]:checked');
    let ids = [];

    checkboxes.each(function(index, checkbox) {
        let id = $(checkbox).data('id');
        if (!id) return;

        let count = $(checkbox)
            .closest('tr')
            .find('input.js-bookmark_amount')
            .val();

        ids.push(id + ':' + count);
    });

    return ids;
};


export {getAllProductIds, getAllCheckedProductIds, getAllCheckedProductIdsAndCounts};