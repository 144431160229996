const Downloads = $('.download');

const ChangeDownloadLink = download => {
    let radioGroup = download.find('input[type=radio]');
    let downloadButton = download.find('.btn');

    if (!radioGroup || !downloadButton) return;

    radioGroup.on('change', function() {
        element = $(this);
        downloadButton.attr('href', element.val());
    });
};

const Init = () => {
    if (!Downloads) return;

    Downloads.each(function() {
        ChangeDownloadLink($(this));
    });
};

Init();
