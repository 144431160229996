const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};

const _getYoutubeIdFromUrl = (url) => {
    if (!url) return;

    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
};

const pauseYoutubeVideo = () => {
    var iframes = document.querySelectorAll('iframe');
    Array.prototype.forEach.call(iframes, (iframe) => {
        iframe.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'stopVideo' }), '*');
    });
};

const convertYoutubeEmbedded = ({ element, url }) => {
    if (!url || !element) return console.warn('No YouTube URL given');

    const id = _getYoutubeIdFromUrl(url);
    const embedUrl = `https://www.youtube.com/embed/${id}/?enablejsapi=1`;

    return element.attr('src', embedUrl);
};

const pauseVideoOnTabChange = () => {
    const tabMenu = $('.nav-tabs');
    const tabItems = $('.nav-tabs .nav-link');
    const modalClose = $('.close');

    if (!tabMenu || !tabMenu.length) return;
    const youtubeVideo = $('.youtube iframe');

    if (!youtubeVideo || !youtubeVideo.length) return console.warn('No YouTube Video found');

    tabItems.each(function (index, element) {
        $(this).on('click', function () {
            pauseYoutubeVideo();
        });
    });

    if (modalClose)
        modalClose.each(function (index, element) {
            $(this).on('click', function () {});
        });

    $('#ajaxModal').on('hidden.bs.modal', function () {
        pauseYoutubeVideo();
    });
};

const lightSliderRefresh = () => {
    const lightSliderContainer = $('.lightSlider');
    const images = $('.lightSlider img');

    if (!lightSliderContainer || !lightSliderContainer.length || !images || !images.length) return;

    let fallback = $('body').find(`.fallback[data-fallback-id="${lightSliderContainer.data('fallback-target-id')}"]`);
    if (fallback.length) {
        fallback.fadeOut('fast', () => {
            fallback.remove();
        });
    }

    const imagesLength = images.length;
    let imagesCount = 0;

    if (imagesLength == 1) return;

    images.on('load', function () {
        imagesCount++;

        console.log('asd', 4);

        if (imagesCount == imagesLength) {
            $('.lightSlider').lightSlider({
                gallery: true,
                item: 1,
                loop: true,
                slideMargin: 0,
                thumbItem: 9,
            });
        }
    });
};

const openNavTab = ({ target = Number }) => {
    if (target === undefined) return;

    const tabList = $('.nav-tabs-container .nav-tabs[role="tablist"]');
    const tabContent = $('.nav-tabs-container .tab-content');
    const tabItems = $('.nav-tabs-container .nav-tabs[role="tablist"]').find('*[data-toggle="tab"]');

    if (!tabList.length || !tabContent.length || !tabItems.length) return console.warn('No TabList found');

    let targetTab = $(tabItems[target - 1]);
    let targetTabId = targetTab.attr('href').replace('#', '');
    let targetContent = tabContent.find(`#${targetTabId}`);

    if (!targetTab.length || !targetContent.length) return console.warn('No TabItem or TabContent found');

    targetTab.addClass('active');
    targetContent.addClass(['show', 'active']);
};

export { debounce, convertYoutubeEmbedded, pauseVideoOnTabChange, lightSliderRefresh, openNavTab };
