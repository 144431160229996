const openUrl = url => {
    if (!url) return;
    window.open(window.location.origin + '/search?q=' + url, '_self');
};

const autoComplete = () => {
    const response = $('#js_autocomplete_json').val();
    const input = $('.js-search');
    if (!response || !input.length) return;

    const engine = new Bloodhound({
        local: JSON.parse(response),
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
    });

    input.typeahead({
        items: 'all',
        autoSelect: false,
        source: engine.ttAdapter(),
        afterSelect: function(event) {
            $(document).on('click', function(e) {
                let target = $(e.target);
                if (!target.hasClass('dropdown-item') || !event) return;
                openUrl(event);
            });
        },
    });
};

const searchOnClick = () => {
    const searchIcon = $('.menu-searchbar__icon');
    if (!searchIcon || !searchIcon.length) return;

    searchIcon.on('click', function() {
        let input = $(this)
            .parent()
            .find('.js-search');

        if (!input.val() || !input.val().length) return;
        openUrl(input.val());
    });
};

const searchPageAccordion = () => {
    const element = $('.category_tree');

    element.on('click', function() {
        $(this).toggleClass('show');
    });
};

const showHideClearInputButton = () => {
    const searchField = $('.menu-searchbar__input');

    searchField.each(function(index, input) {
        const inputField = $(this);
        const clearButton = inputField.parent().find('.menu-searchbar__clear');

        if (!clearButton || !clearButton.length) return;
        if (inputField.val().length > 0) clearButton.addClass('show');

        inputField.on('change', function(e) {
            if ($(this).val().length == 0) return clearButton.removeClass('show');
            clearButton.addClass('show');
        });

        clearButton.on('click', function() {
            inputField.val('');
            clearButton.removeClass('show');
        });
    });
};

showHideClearInputButton();

searchOnClick();
autoComplete();
searchPageAccordion();
