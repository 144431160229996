import Sortable from 'sortablejs';

let sortableList = $('#is_bookmark_sortable_disabled').val() == 'true' ? true : false;
if (!sortableList) $('div[data-sortable="item"]').addClass('sortable__item--empty');

if (document.querySelector('div[data-sortable="list"]'))
    new Sortable(document.querySelector('div[data-sortable="list"]'), {
        animation: 150,
        ghostClass: 'sortable__item--drag',
        disabled: sortableList,
        delay: 180,
        delayOnTouchOnly: false,
        onSort: () => updateBookmarks(),
    });

const updateBookmarks = () => {
    $.ajax($('#bookmarks_ajax_sort').val(), {
        method: 'POST',
        data: {
            _token: $('#_token').val(),
            products_id: getAllBookmarks(),
        },
        success: function (resp) {
            console.log('%c Bookmarks successfully updated!', 'color: #bada55');
        },
        error: function (resp) {
            console.log('%c Bookmarks could not be updated!', 'color: #red');
        },
    });
};

const getAllBookmarks = () => {
    let bookmarks = [];

    $('.js-bookmark-item').each(function () {
        let id = $(this).data('id');
        bookmarks.push(id);
    });

    return bookmarks.join(',') || '';
};

$(document).on('click', '.bookmarks_add_multiple', function (e) {
    e.preventDefault();
    let isAuthenticated = $('#_authenticated').val();

    var ids = [];
    const amount = [];

    $(this)
        .closest('.product-list')
        .find('.products input[type=checkbox]:checked')
        .each(function () {
            ids.push($(this).closest('tr').data('id'));
        });

    if (ids.length == 0) {
        alert($('#i18_bookmarks_no_selection').val());
        return;
    }

    $(this)
        .closest('.product-list')
        .find('.js-bookmark_amount')
        .each(function () {
            if ($(this).val() == 0) {
                return;
            }
            amount.push($(this).val());
        });

    if (!isAuthenticated) {
        $.ajax($('#bookmarks_ajax_add_multiple_url').val(), {
            method: 'POST',
            data: {
                _token: $('#_token').val(),
                products_id: ids.join(','),
            },
            success: function (resp) {
                $('.alert').hide().html($('#i18n_bookmarks_ajax_add_multiple_url_success').val()).show();

                setBadge();
            },
        });

        return;
    }

    $.get(
        $('#url_bookmarks-chose_list-get').val(),
        {
            _token: $('#_token').val(),
            ids: ids.join(','),
            amount: amount.join(','),
        },
        function (data) {
            $('#ajaxModal').html(data);
            $('#ajaxModal').modal('show');

            $('#ajaxModal').find('select').select2({
                selectOnClose: false,
            });
        }
    );
});

$(document).on('click', '.js-select-all-products', function (e) {
    e.preventDefault();

    let parent = $(this).closest('.dataTables_wrapper');
    let self = $(this).find('input[type="checkbox"]');
    let checkboxes = parent.find('tbody input[type="checkbox"]');
    let checked = self.prop('checked');

    self.prop('checked', !checked);

    checkboxes.each(function (i, element) {
        if (checked) {
            return $(this).prop('checked', false);
        }
        return $(this).prop('checked', true);
    });
});

$(document).on('click', '.bookmark-list_chose-list_submit_btn', function (e) {
    var self = $(this);
    e.preventDefault();

    if ($('#bookmark-list_chose-list_form').valid()) {
        let data = $('#bookmark-list_chose-list_form').serializeArray();

        data.push({
            name: '_token',
            value: $('#_token').val(),
        });

        $.post($('#url_bookmarks-chose_list-post').val(), data, function (data) {
            $('#ajaxModal').modal('hide');
            setBadge();
        });
        return;
    }
});

$(document).on('click', '.bookmark_rename_list', function (e) {
    let id = $(this).data('id');

    $.get(
        $('#url_bookmark_lists_rename_get').val() + '/' + id,
        {
            _token: $('#_token').val(),
        },
        function (data) {
            $('#ajaxModal').html(data);
            $('#ajaxModal').modal('show');
        }
    );
});

$(document).on('click', '.bookmark_rename_submit_btn', function (e) {
    var self = $(this);
    e.preventDefault();

    if ($('#bookmark_lists_rename_form').valid()) {
        let data = $('#bookmark_lists_rename_form').serializeArray();

        data.push({
            name: '_token',
            value: $('#_token').val(),
        });

        $.post($('#url_bookmark_lists_rename_post').val(), data, function () {
            $('#ajaxModal').modal('hide');

            window.location.reload();
        });

        return;
    }
});

$(document).on('click', '.bookmarks_remove', function (e) {
    var self = $(this);
    e.preventDefault();
    $.ajax($('#bookmarks_ajax_remove_url').val(), {
        method: 'POST',
        data: {
            _token: $('#_token').val(),
            product_id: $(this).data('id'),
        },
        success: function (resp) {
            $('.alert').hide().html($('#i18n_bookmarks_ajax_remove_url_success').val()).show();

            self.hide();
            self.parent().find('.bookmarks_add').show();

            updateCartBadge(resp.count);
        },
    });
});

$(document).on('change', '.js-bookmark_amount', function (e) {
    if ($('.result-item__products').data('type') != 'filter') {
        var self = $(this);

        $.ajax($('#bookmarks_ajax_change_amount').val(), {
            method: 'POST',
            data: {
                _token: $('#_token').val(),
                product_id: $(this).data('id'),
                amount: parseInt(self.val()),
            },
            success: function (resp) {
                updateCartBadge(resp.count);
            },
        });
    }
});

$(document).on('click', '.bookmarks-reset', function (e) {
    var self = $(this);

    if (confirm($('#i18_bookmarks_reset_confirm').val())) {
        $.ajax($('#bookmarks_ajax_reset_url').val(), {
            method: 'GET',
            success: (resp) => {
                window.location.reload();
            },
        });
    }
});

$(document).on('click', '.bookmark_remove_list', function (e) {
    var self = $(this);

    if (confirm($('#i18_bookmarks_remove_confirm').val())) {
        $.ajax($('#bookmarks_ajax_delete_url').val(), {
            method: 'GET',
            success: (resp) => {
                window.location.reload();
            },
        });
    }
});

$(document).on('click', '.bookmarks-new', function () {
    var self = $(this);

    $.ajax($('#url_bookmark-lists_save-as-new-list_post').val(), {
        method: 'GET',
        data: {
            _token: $('#_token').val(),
            product_ids: '',
        },
        success: (resp) => {
            $('#ajaxModal').html(resp);
            $('#ajaxModal').modal('show');
        },
    });
});

$(document).on('click', '.bookmark_remove_from_list', function (e) {
    // Bookmark Liste!
    var self = $(this);
    e.preventDefault();

    $.ajax($('#bookmarks_ajax_remove_url').val(), {
        method: 'POST',
        data: {
            _token: $('#_token').val(),
            product_id: $(this).closest('.col-12').data('id'),
        },
        success: function (resp) {
            $('.alert').hide().html($('#i18n_bookmarks_ajax_remove_url_success').val()).show();

            self.closest('.col-12').remove();

            if ($('#products .result').length == 0) {
                $('#no_bookmarks').show();
                $('#contact_area').hide();
            }

            updateCartBadge(resp.count);
        },
    });
});

$(document).on('click', '.bookmarks_add', function (e) {
    e.preventDefault();

    var self = $(this);
    var visibleAfterClick = $(this).data('visible') ? $(this).data('visible') : false;
    let isAuthenticated = $('#_authenticated').val();
    let ids = [$(this).data('id')];

    if (!isAuthenticated) {
        $.ajax($('#bookmarks_ajax_add_url').val(), {
            method: 'POST',
            data: {
                _token: $('#_token').val(),
                product_id: $(this).data('id'),
            },
            success: function (resp) {
                $('.alert').hide().html($('#i18n_bookmarks_ajax_add_url_success').val()).show();

                if (!visibleAfterClick) {
                    self.hide();
                    self.parent().find('.bookmarks_remove').show();
                }

                updateCartBadge(resp.count);
            },
        });

        return;
    }

    $.get(
        $('#url_bookmarks-chose_list-get').val(),
        {
            _token: $('#_token').val(),
            ids: ids.join(','),
        },
        function (data) {
            $('#ajaxModal').html(data);
            $('#ajaxModal').modal('show');

            $('#ajaxModal').find('select').select2({
                selectOnClose: false,
            });
        }
    );
});

const updateCartBadge = (count) => {
    let badge = $('.bookmark-badge');
    if (!badge.length) return;
    badge.removeClass('hide');
    if (!count) badge.addClass('hide');
    return badge.text(count);
};

const setBadge = () => {
    let url = $('#bookmarks_ajax_get_cart_size').val();
    if (!url || url === undefined) return;

    $.ajax(url, {
        method: 'GET',
        success: ({ count }) => {
            updateCartBadge(count);
        },
    });
};

setBadge();

const loadUi = (callback) => {
    $('.products.datatable').each(function () {
        let colCount = $(this).find('th').length;
        let defaultSort = $(this).data('defaultsort');
        let ordering = [[1, 'asc']];

        if (defaultSort != '') {
            $(this)
                .find('th')
                .each(function (i) {
                    let key = $(this).data('key');

                    if (key == defaultSort) {
                        ordering = [[i, 'asc']];
                    }
                });
        }

        let columnsDefs = [
            {
                targets: 0,
                orderable: false,
            },
        ];

        colCount--; // Erste Spalte abziehen

        for (var i = 1; i <= colCount; i++) {
            let th = $(this).find('th').get(i);

            columnsDefs.push({
                targets: i,
                orderable: !$(th).hasClass('nosort'),
                type: 'numeric-comma',
            });
        }

        $(this).DataTable({
            order: ordering,
            info: false,
            paging: false,
            searching: false,
            scrollX: true,
            columnDefs: columnsDefs,
        });
    });

    callback ? callback() : false;
};

const wait = () => {
    if (!$($.fn.dataTable.tables(true)) || !$($.fn.dataTable.tables(true)).length) {
        setTimeout(wait, 200);
    } else {
        $($.fn.dataTable.tables(true)).DataTable().columns.adjust();
    }
};

window.addEventListener('DOMContentLoaded', function () {
    if (!$('.bookmark-controls').length) return;
    loadUi();
    wait();
});
