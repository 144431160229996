const openUrl = (url) => {
    if (!url) return;

    window.open(window.location.origin + '/search?q=' + url, '_self');
};

const autoComplete = () => {
    const path = $('#shop_ajax_get_autocomplete_as_json_array_get').val();

    // $('.js-search').typeahead({
    //     minLength: 3,
    //     delay: 300,
    //     matcher: function (item) {
    //         return true;
    //     },

    //     source: function (query, process) {
    //         return $.get(
    //             path,
    //             {
    //                 query: query,
    //             },
    //             function (data) {
    //                 console.log(data);
    //                 return process(data);
    //             }
    //         );
    //     },
    // });

    $('.js-search').autocomplete({
        serviceUrl: path,
        deferRequestBy: 300,
        onSelect: function (suggestion) {
            window.open(suggestion.data);
        },
    });

    $('.js-search').on('blur', function () {
        setTimeout(() => {
            $('.js-search').autocomplete('clear');
        }, 300);
    });
};

const searchOnClick = () => {
    const searchIcon = $('.menu-searchbar__icon');
    if (!searchIcon || !searchIcon.length) return;

    searchIcon.on('click', function () {
        let input = $(this).parent().find('.js-search');
        if (!input.val() || !input.val().length) return;
        openUrl(input.val());
    });
};

const searchOnEnter = () => {
    const searchBar = $('.menu-searchbar__input');
    if (!searchBar || !searchBar.length) return;

    searchBar.on('keypress', function (e) {
        if (e.which == 13) {
            let input = $(this).parent().find('.js-search');
            if (!input.val() || !input.val().length) return;
            openUrl(input.val());
        }
    });
};

const searchPageAccordion = () => {
    const element = $('.category_tree');

    element.on('click', function () {
        $(this).toggleClass('show');
    });
};

const showHideClearInputButton = () => {
    const searchField = $('.menu-searchbar__input');

    searchField.each(function (index, input) {
        const inputField = $(this);
        const clearButton = inputField.parent().find('.menu-searchbar__clear');

        if (!clearButton || !clearButton.length) return;
        if (inputField.val().length > 0) clearButton.addClass('show');

        inputField.on('change', function (e) {
            if ($(this).val().length == 0) return clearButton.removeClass('show');
            clearButton.addClass('show');
        });

        clearButton.on('click', function () {
            inputField.val('');
            clearButton.removeClass('show');
        });
    });
};

showHideClearInputButton();

searchOnClick();
autoComplete();
searchPageAccordion();
searchOnEnter();
