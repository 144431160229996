import animateScrollTo from 'animated-scroll-to';

const menu = $('.megamenu');
let navItem = $('.megamenu__item');

navItem.on('click', function(event) {
    let self = $(this);
    let element = self.attr('data-name');
    let nav = menu.find(`[data-parent="${element}"]`);

    if (nav.length >= 1) {
        hideAllMenus();
        toggleMenu(nav, self);
    }
});

$(document).on('click', '.megamenu .close', e => {
    hideAllMenus();
});

$(document).mouseup(e => {
    let menu = $('.megamenu__nav');

    if (!menu.is(e.target) && menu.has(e.target).length === 0) {
        hideAllMenus();
    }
});

const toggleMenu = (element, parent) => {
    parent.toggleClass('active');
    element.toggleClass('open');
    element.attr('megamenu-type', element.attr('megamenu-type') == 'hidden' ? 'show' : 'hidden');

    if ($(document).width() < 757) {
        animateScrollTo($(element)[0]);
    }
};

const hideMenu = element => {
    element.removeClass('open');
    element.attr('megamenu-type', 'hidden');
};

const hideAllMenus = () => {
    let menus = menu.find('[data-parent]');
    let menuParents = menu.find('[data-name]');

    menus.each(function(index, element) {
        let self = $(this);
        hideMenu(self);
    });

    menuParents.each(function(index, element) {
        let self = $(this);
        self.removeClass('active');
    });
};

const addActiveMenu = () => {
    let activeMenu = $('.js-active-menu').data('value');
    let menuItems = $('.megamenu__item');

    if (activeMenu)
        $.each(menuItems, function(index, element) {
            if ($(element).data('menu') == activeMenu) {
                $(element)
                    .find('.megamenu__item-container')
                    .addClass('active-item');
            }
        });
};

addActiveMenu();

const ToggleMobileMenu = () => {
    const DropdownToggle = $('[data-dropdown-toggle]');
    const Body = $('body');

    DropdownToggle.on('click', function(e) {
        e.stopPropagation();

        let element = $(this);
        let itemToToggle = element.data('dropdown-toggle');
        let dropDown = $('body').find(`[data-dropdown="${itemToToggle}"]`);

        if (!dropDown || !dropDown.length) return;
        dropDown.toggleClass(`mainnavigation-mobile__item--active`);
    });

    $(document).on('click', function(e) {
        if ($(e.target).closest('[data-dropdown-container').length != 0) return;

        let toHide = $('[data-dropdown]');
        toHide.each(function() {
            $(this).removeClass('mainnavigation-mobile__item--active');
        });
    });
};

ToggleMobileMenu();
